@import './variables.scss';

html{
    height: 101%;
    scroll-behavior: smooth;
  }
  
html, body{
padding: 0;
margin: 0;
width: 100%;
}

body{
    background-color: #{$secondary};
    padding: 0;
    margin: 0;
    font-family: "Maison Neue", sans-serif;

    div, header, main, footer, section{
        box-sizing: border-box;
    }

    &.no-scroll{
        overflow-y: hidden !important;
    }

}

@import './warning.scss';

#chronic-house{

    width: 100%;
    min-height: 100%;
    max-width: #{$max-screen-width};

    @import './login.scss';

    &.active{
        nav{
            left: 0;
        }
        .header{
            background-color: transparent;
            .menu-button{
                color: #{$primary};
            }
        }
        .main-logo{
            color: #{$primary};
        }
        .cta-button{
            opacity: 0;
        }
    }

    .cta-button{
        background: #{$secondary};
        color: #{$primary};
        border: none !important;
        border-radius: 40px;
        transition: all #{$transisition-time} #{$transisition-easing};
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
        font-family: "Maison Neue" !important;
        text-decoration: none;
    }

    .pull-quote-image{
        margin-bottom: 60px;
        margin-top: 60px;
        width: 100%;
        height: auto;
        padding-left: 32px;
        padding-right: 32px;
    }

    .pull-quote{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;
        margin-top: 28px;
        padding: 0 40px;

        font-family: Apoc LC;
        font-style: italic;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #{$primary};
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss02' on, 'calt' off;

        div{
            width: 100%;

            &.top{
                margin-bottom: 24px;
            }
            &.bottom{
                margin-top: 24px;
            }
        }
    }

    @import './header.scss';
    @import './nav.scss';
    @import './footer.scss';
    @import './terms.scss';

    .content{
        z-index: 1;
        main{
            position: relative;
            @import './home.scss';
            @import './about.scss';
            @import './beliefs.scss';
            @import './space.scss';
            @import './amenities.scss';
            @import './whatyouget.scss';
            @import './Vision.scss';
            @import './survey.scss';
            @import './checkback.scss';
            input {
                border-radius: 0;
              }
            input[type=search], input[type=text], input[type=password] {
            -webkit-appearance: none;
            }
        }
    }
}