.about{

    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 64px;

    .to-date{
        margin-top: 0 !important;
    }

    h3{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
        color:  #{$primary};
        margin: 0;
        padding-bottom: 16px;
    }

    h2{
        font-family: 'Voyage';
        font-style: normal;
        font-weight: bold;
        font-size: 72px;
        line-height: 64px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color:  #{$primary};
        margin: 0;
        padding-bottom: 32px;
    }

    p{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'salt' on, 'calt' off, 'liga' off;
        color:  #{$primary};
        padding-bottom: 32px;
        margin: 0 40px;

        a{
            display: inline-block;
        }

        &.less-bottom{
            padding-bottom: 0 !important;
        }

        a, a:visited{
            color: #{$primary};
        }
    }

    .image-group-1{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 64px;

        img{
            position: relative;
        }

        img:first-of-type{
            left: 48px;
            z-index: 2;
            width: 160px;
            height: auto;
        }
        
        img:last-of-type{
            width: 272px;
            height: auto;
        }

    }

    .interrior-beauty{
        margin-bottom: 64px;
        width: 264px;
    }

    .image-group-2{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 64px;

        img:first-of-type{
            width: 164px;
            height: auto;
        }

        img:last-of-type{
            width: 188px;
            height: auto;
        }
    }

    .image-group-3{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;

        .group-a, .group-b{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            div:first-child{
                margin-right: 16px;
            }

            div:last-child{
                margin-left: 16px;
            }
        }

        .group-a{
            margin-bottom: 32px;
        }

        .group-b{
            margin-bottom: 48px;
        }

    }

    .slider-container{
        width: 100%;
        padding-bottom: 98px;

        .slider-item{

            img.big.small {
                position: relative;
                top: 24px;
            }
            
            .data-wrapper{
                padding-top: 6px !important;
            }
            
            img.big.small ~ .data-wrapper{
                position: relative;
                top: 18px;
            }

            .wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            img{
                &.big{
                    width: 240px;
                    height: auto;
                    &.small{
                        position: relative;
                        width: 184px;
                        height: auto;
                    }
                }
            }
        
            .data-wrapper{

                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                justify-content: center;

                a{
                    font-family: Maison Neue;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                    color: #{$primary};
                }
            }
            .avatar{
                border-radius: 40px;
                margin-right: 8px;
            }
        }

    }
}