.beliefs{
    background-color: #{$primary};
    color: #{$secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 72px 24px;

    font-family: Maison Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;

    h2{
        font-family: Voyage;
        font-style: normal;
        font-weight: bold;
        font-size: 68px;
        line-height: 64px;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'salt' on;
        text-align: center;
    }

    p{
        text-align: center;
    }

    .linkout-box{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-top:  2px solid #{$secondary};
        border-bottom:  2px solid #{$secondary};
        box-sizing: border-box !important;

        .avatar-container{
            padding: 20px;
            padding-left: 0;
            box-sizing: border-box !important;
            img{
                width: 64px;
                height: auto; 
            }
        }
        .link-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 20px;
            padding-right: 0;
            border-left:  2px solid #{$secondary};
            box-sizing: border-box !important;

            a{
                color: #{$secondary};
                
            }
        }
    }
}