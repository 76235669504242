.survey{
    width: 100%;
    color: #{$primary};
    padding: 0 0;
    background-color: #{$white};
    max-width: 100%;
    overflow-x: hidden;

    h2{
        font-family: "Voyage";
        font-style: normal;
        font-weight: bold;
        font-size: 80px;
        line-height: 72px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.025em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
        width: 100%;
        text-align: center;
        padding-top: 64px !important;
        margin-bottom: 32px;
        margin-top: 0 !important;
    }

    p{
        font-family: "Maison Neue";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }

    .survey-wrapper{

        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;

        .survey-section{
            h3{
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                font-feature-settings: 'ss07' on, 'calt' off;
                width: 100%;
                border-bottom: 1px solid #{$primary};
                margin-bottom: 40px !important;
                padding-bottom: 16px;
                margin-left: 16px;
            }
            h4{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: 0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
            }
            .question{
                display: flex;
                flex-direction: column;
                justify-content: left;
                padding-left: 16px;
                padding-right: 16px;
                p{
                    width: 100%;
                    text-align: left !important;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: 0.01em;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                }
                ul{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    width: 100%;

                    li{
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 20px;
                        font-weight: 600;

                        &.sub-copy{
                            top: -10px;
                            padding-left: 47px;
                            margin-bottom: 10px !important;
                            font-style: normal;
                            &.checkbox{
                                padding-left: 55px;
                            }
                            span{
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.02em;
                                font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                            }
                        }
                    }

                    &.reverse{
                        li{
                            flex-direction: row-reverse !important;
                            justify-content: flex-end;
                            span{
                                width: 100%;
                            }
                        }
                    }
                }
                [type=checkbox]{
                    display: block;
                    text-align: center;
                    appearance: none;
                    box-shadow: none;
                    outline: none;
                    border: 2px solid #{$primary};
                    border-radius: 2px;
                    font-family: Maison Neue;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 0.02em;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                    width: 30px;
                    height: 30px;
                    margin-right:18px;
                    transition: all 0.25s linear;
                    &:checked{
                        background-color: #{$primary};
                        color: #{$secondary};
                        &::after{
                            content: "✓";
                            font-size: 1rem;
                            position: relative;
                            top: -1px
                        }
                    }
                }
                input[type=radio]{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin :0;
                    z-index: 2;
                    opacity: 0;
                    color: #{$primary};
                    &:checked ~ .check {
                        border: 8px solid#{$primary};
                    }
                }
                .check{
                    display: block;
                    border: 2px solid #{$primary};
                    border-radius: 100%;
                    height: 29px;
                    width: 29px;
                    transition: border .25s linear;
                    -webkit-transition: border .25s linear;
                    margin-right: 18px;
                    &::before {
                        display: block;
                        position: absolute;
                        content: '';
                        border-radius: 100%;
                        height: 15px;
                        width: 15px;
                        top: 5px;
                        left: 5px;
                        margin: auto;
                        transition: all 0.25s linear;
                    }
                }
                [type=text], [type=email]{
                    border: none;
                    border-bottom: 1px solid #{$primary};
                    padding-bottom: 8px;
                    font-family: Maison Neue;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px !important;
                    line-height: 28px;
                    letter-spacing: 0.01em;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                    width: 100%;
                    outline: none !important;
                }
                .question-error{
                    width: 100%;
                    color: #{$red};
                    margin-bottom: 1rem;
                }
            }

            &.waitlist{
                p{
                    margin: 0;
                    margin-bottom: 40px;
                    &.title{
                        margin-bottom: 32px;
                    }
                    &.blurb{
                        font-weight: 400;
                    }
                }
            }

            &.submit{
                button{
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    font-feature-settings: 'salt' on;
                    padding: 16px 24px;
                    border-radius: 100px;
                    background-color: #{$primary};
                    color: #{$secondary};
                    border: none !important;
                }
            }
        }
    }
}