.check-back{
    height: 100vh;
    color: #{$primary};
    background-color: #{$white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;

    h2{
        font-family: "Voyage";
        font-style: normal;
        font-weight: bold;
        font-size: 80px;
        line-height: 72px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'salt' on;
        margin-top: 0;
        width: 300px;
    }
    p{
        font-family: "Maison Neue";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }
}