footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #{$primary};
    color: #{$secondary};

    .logo-container{
        margin-top: 72px;
        margin-bottom: 42px;
    }

    .footer-logo{
        width: 128px;
        height: auto;
    }

    .footer-links{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;

        ul{
            list-style: none;
            text-align: center;
            padding: 0;
            margin: 0 16px;
            li{
                margin-bottom: 16px;
                a{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    font-feature-settings: 'salt' on;
                    color: #{$secondary};
                    text-decoration: none;
                    &:active, &:visited{
                        color: #{$secondary};
                        text-decoration: none;
                    }
                }
            }
        }
    }

    h2{
        font-family: "Voyage";
        margin-top: 56px;
        margin-bottom: 32px;
        font-family: Voyage;
        font-style: normal;
        font-weight: bold;
        font-size: 72px;
        line-height: 64px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
    }

}