.vision{
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 64px;
    color: #{$primary};
    width: 100%;
    max-width: 100% !important;

    .pull-quote-image ~ p{
        margin-top: 0 !important;
    }

    .leader{
        
        width: 100%;
        max-width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 116px;
        min-height: 375px;

        .vision-title{
            
        }

        img:nth-of-type(1){
            position: relative;
            top: 124px;
            width: 112px  !important;
            height: auto;
        }

        img:nth-of-type(3){
            position: relative;
            top: 308px;
            width: 112px  !important;
            height: auto;
        }

    }

    .art-deco{
        width: 328px;
        height: auto;
        margin-bottom: 20px;
    }

    .studio-space{
        width: 100%;
        height: auto;
    }

    h3{
        margin: 24px;
        font-family: 'Maison Neue';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
    }

    p{
        margin: 24px;
        font-family: 'Maison Neue';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;

        &.lead-paragraph{
            margin-bottom: 64px;
        }
    }

    h4{
        font-family: Maison Neue;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-feature-settings: 'ss07' on, 'calt' off;
        text-align: left;
        &.hassle-free{
            width: 100%;

            span{
                display: block;
                width: calc(100% - 64px);
                margin-left: 32px;
                margin-right: 32px;
                border-bottom: 1px solid #{$primary};
                padding-bottom: 12px;
            }
        }
    }

    .image-layout-1{
        width: 100%;
        margin-left: 24px;
        margin-right: 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;

        img:nth-of-type(1){
            width: 164px;
            height: auto;
        }

        img:nth-of-type(2){
            width: 140px;
            height: auto;
        }

        &.reverse{
            margin-top: 24px !important;
            margin-bottom: 40px !important;
            img:nth-of-type(1){
                width: 140px;
                height: auto;
            }
    
            img:nth-of-type(2){
                width: 164px;
                height: auto;
            }
        }
    }

    .vision-gallery{
        width: 280px;
        height: auto;
        margin-bottom: 40px;
    }

    .pull-quote{
        margin-bottom: 30px !important;
    }
    
}