.login{
    
    min-height: 100vh;
    min-width: 100%;
    padding: 16px;
    background-color: #{$primary};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

     .welcome{
         width: 112px;
         height: auto;
         margin-bottom: 20px;
     }

    h1{
        font-family: 'Voyage', serif;
        font-style: normal;
        color: #{$secondary};
        font-weight: bold;
        font-size: 72px;
        line-height: 64px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
        margin-top: 0;
    }

    p{
        width: 100%;
        text-align: left;
        position: relative;
        margin-bottom: 40px;

        label{
            font-family: "Maison Neue";
            width: 100%;
            margin-bottom: 8px !important;
            text-align: left;
            color: #{$secondary};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
        }

        input{
            color: #{$secondary};
            background-color: transparent !important;
            border: none;
            outline: none;
            border-bottom: 2px solid #{$secondary};
            padding-bottom: .5rem;
            padding-top: .5rem;
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
        }

        &.submit{
            text-align: center !important;
        }

        .cta-button{
            font-size: 14px !important;
            line-height: 24px !important;
            padding: 12px 24px;
        }

        #pword-toggle{
            position: absolute;
            top: 25px;
            left: calc(100% - 25px);
            display: block;
            width: 24px;
            height: 24px;
            background-image: url(../images/eye-off.png);
            background-size: contain;

            &.on{
                background-image: url(../images/eye-on.png); 
            }
        }
    }
}