.amenities{

    width: 100%;
    margin-top: 72px;
    margin-bottom: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #{$primary};
    max-width: 100%;
    overflow-x: hidden;

    h2{
        font-family: Maison Neue;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
    }

    h3{
        font-family: Voyage;
        font-style: normal;
        font-weight: bold;
        font-size: 88px;
        line-height: 76px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.04em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss02' on;
        margin-top: 20px;
    }

    .art-room{
        margin-bottom: 64px;
        width: 100%;
        height: auto;
    }

    .image-left{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 64px;
        padding-left: 24px;
        img{
            width: 248px;
            height: auto;
        }
    }

    .image-right{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 56px;
        img{
            width: 164px;
            height: auto;
        }
    }

    .couch{
        margin-top: 64px;
        width: 264px;
        height: auto;
    }

    .image-right{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .photo{
        margin-top: 64px;
        width: 100%;
        height: auto;

    }

    .image-split{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .outdoor{
            width: 164px;
            height: auto;
            align-self: flex-start;
            margin-left: 56px;
        }

        .gym{
            width: 216px;
            height: auto;
        }

        .image-stack{

            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            img:nth-of-type(1){
                width: 164px;
                height: auto;
            }

            img:nth-of-type(2){
                width: 188px;
                height: auto;
            }

        }

    }
}