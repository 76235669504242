#warning{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #{$primary};
    color: #{$secondary};
    padding: 2rem;

    img{
        width: 15vw;
        height: auto;
    }

    h1{
        font-family: 'Voyage';
        font-style: normal;
        font-weight: bold;
        font-size: 6rem;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.015em;
    }
    p{
        font-family: 'Maison Neue';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
    }
}


@media(min-width: 600px){
    #chronic-house{
        display: none;
    }
    #warning{
        display: flex;
    }
}

@media(max-width: 599px){
    #chronic-house{
        display: block;
    }
    #warning{
        display: none;
    }
}