.terms{
    
    background-color: #{$secondary};
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    .title{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #{$primary};
        h2{
            color: #{$primary};
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            font-feature-settings: 'salt' on;
        }
    }

    .scrollable-terms-copy{
        padding: 24px;
        max-height: calc(100vh - 150px);
        overflow-y: scroll;
        color: #{$primary};
        font-family: Maison Neue;

        h3{
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -0.01em;
        }

        p{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            font-feature-settings: 'salt' on;
        }

        dl{

            dt{
                margin-bottom: .5rem;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.01em;
                font-feature-settings: 'salt' on;
            }
            dd{
                margin: 0;
                margin-bottom: 1rem;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.01em;
                font-feature-settings: 'salt' on;
            }
        }
    }

    .acceptance{
        padding: 16px;
        background-color: #{$secondary};
        border-top: 1px solid #{$primary};
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: #{$max-screen-width};
    }

    .terms-signature{
        input{
            background-color: transparent;
            outline: none;
            border: none;
            border-bottom: 1px solid #{$primary};
            color: #{$primary};
            width: 100%;
            font-family: Maison Neue;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
            margin-bottom: 24px;
        }
    }

    .terms-submit{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button{
            background: #{$primary};
            border: 1px solid #{$primary};
            box-sizing: border-box;
            border-radius: 100px;
            font-family: Maison Neue;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            font-feature-settings: 'salt' on;
            padding: 12px 32px;
            color: #{$secondary};
        }
    }
}

/* Customize the label (the container) */
.checkbox-wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #{$primary};

    font-family: Maison Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: #{$primary};
        }
        &:checked ~ .checkmark {
            color: #{$secondary};
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        color: #{$primary};
        background-color: #{$primary};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &:hover input ~ .checkmark {
        background-color: #{$primary};
      }

      &::after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid #{$secondary};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      } 
}