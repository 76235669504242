.wdyg{

    background-color: #{$primary};
    color: #{$secondary};
    font-family: "Maison Neue";

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lead{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        h2{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            font-feature-settings: 'salt' on;
        }
    
        h3{
            font-family: Voyage;
            font-style: normal;
            font-weight: bold;
            font-size: 128px;
            line-height: 104px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            margin-top: 28px !important;
            margin-bottom: 40px !important;
        }

        p{
            margin-left: 40px;
            margin-right: 40px;
            font-family: Maison Neue;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            margin-bottom: 34px;
        }

        .arrow{
            width: auto;
            height: 32px;
            margin-bottom: 142px;
        }
    }

    .perks-include{

        h3{
            display: block;
            width: 100%;
            margin-top: 0 !important;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            font-feature-settings: 'ss07' on, 'calt' off;
            border-bottom: 2px solid #{$secondary};
            span{
                display: block;
                margin-left: 24px;
                padding-bottom: 16px !important;
            }
        }

        .list-area{
            margin-top: 64px;
            padding-left: 24px;
            padding-right: 24px;
            width: 100%;

            &.final{
                margin-bottom: 64px;
            }

            ol{
               list-style: none;
               margin: 0;
               padding: 0;
               width: 100%;
               
               li{
                div{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                    margin-bottom: 40px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-items: first baseline;
                    width: 100%;

                    &.has-sub{
                        margin-bottom: 10px !important;
                    }

                    span.marker{
                        span{
                            display: block;
                            position: relative;
                            top: 10px;
                            width: 32px;
                            height: 32px;
                            text-align: center;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 14px;
                            font-feature-settings: 'pnum' on, 'lnum' on, 'case' on;
                        }
                        background-color: #{$secondary};
                        color: #{$primary};
                        border-radius: 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-right: 24px;
                    }
                  }
                  ul{
                    padding-left: 75px !important;
                    margin-bottom: 40px;
                    li{
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 28px;
                        letter-spacing: 0.02em;
                        font-feature-settings: 'pnum' on, 'lnum' on, 'ss07' on, 'calt' off;
                    }
                }
               }
            }
        }

        .logo-area{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 72px;

            img{
                height: 48px;
                width: auto;
                margin-bottom: 32px;
            }

        }

    }

}