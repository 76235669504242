.home{
    background-color: #{$primary};
    color: #{$secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;

    h1, h2{
        text-transform: uppercase;
        text-align: center;
    }

    h1{
        font-family: 'Voyage', serif;
        font-weight: 700;
        font-size: 72px;
        line-height: 64px;
        font-style: normal;
        padding-top: 42px;
        padding-bottom: 32px;
        margin: 0 !important;
    }

    h2{
        font-weight: 600;
        line-height: 40px;
        font-size: 32px;
        margin: 0 !important;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .home-image{
        margin: 0 !important;
        padding-bottom: 32px;
        width: 184px;
        height: auto;

    }

    .cta-button{
        margin-bottom: 76px;
        padding: 16px 32px;
        
    }

}