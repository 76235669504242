@font-face{
    font-family: 'Chronic';
    src: url('../fonts/chronic-store.ttf') format('truetype');
}

@font-face {
    font-family: 'Voyage';
    src: url('../fonts/Voyage-Regular.eot');
    src: url('../fonts/Voyage-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Voyage-Regular.woff2') format('woff2'),
        url('../fonts/Voyage-Regular.woff') format('woff'),
        url('../fonts/Voyage-Regular.ttf') format('truetype'),
        url('../fonts/Voyage-Regular.svg#Voyage-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Voyage';
    src: url('../fonts/Voyage-Bold.eot');
    src: url('../fonts/Voyage-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Voyage-Bold.woff2') format('woff2'),
        url('../fonts/Voyage-Bold.woff') format('woff'),
        url('../fonts/Voyage-Bold.ttf') format('truetype'),
        url('../fonts/Voyage-Bold.svg#Voyage-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Extra';
    src: url('../fonts/MaisonNeue-ExtraBold.eot');
    src: url('../fonts/MaisonNeue-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-ExtraBold.woff2') format('woff2'),
        url('../fonts/MaisonNeue-ExtraBold.woff') format('woff'),
        url('../fonts/MaisonNeue-ExtraBold.ttf') format('truetype'),
        url('../fonts/MaisonNeue-ExtraBold.svg#MaisonNeue-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Extra';
    src: url('../fonts/MaisonNeue-ExtraThin.eot');
    src: url('../fonts/MaisonNeue-ExtraThin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-ExtraThin.woff2') format('woff2'),
        url('../fonts/MaisonNeue-ExtraThin.woff') format('woff'),
        url('../fonts/MaisonNeue-ExtraThin.ttf') format('truetype'),
        url('../fonts/MaisonNeue-ExtraThin.svg#MaisonNeue-ExtraThin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Light.eot');
    src: url('../fonts/MaisonNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Light.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Light.woff') format('woff'),
        url('../fonts/MaisonNeue-Light.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Light.svg#MaisonNeue-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Demi';
    src: url('../fonts/MaisonNeue-Demi.eot');
    src: url('../fonts/MaisonNeue-Demi.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Demi.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Demi.woff') format('woff'),
        url('../fonts/MaisonNeue-Demi.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Demi.svg#MaisonNeue-Demi') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Bold.eot');
    src: url('../fonts/MaisonNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Bold.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Bold.woff') format('woff'),
        url('../fonts/MaisonNeue-Bold.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Bold.svg#MaisonNeue-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Mono';
    src: url('../fonts/MaisonNeueMono-Bold.eot');
    src: url('../fonts/MaisonNeueMono-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeueMono-Bold.woff2') format('woff2'),
        url('../fonts/MaisonNeueMono-Bold.woff') format('woff'),
        url('../fonts/MaisonNeueMono-Bold.ttf') format('truetype'),
        url('../fonts/MaisonNeueMono-Bold.svg#MaisonNeueMono-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Black.eot');
    src: url('../fonts/MaisonNeue-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Black.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Black.woff') format('woff'),
        url('../fonts/MaisonNeue-Black.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Black.svg#MaisonNeue-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Thin.eot');
    src: url('../fonts/MaisonNeue-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Thin.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Thin.woff') format('woff'),
        url('../fonts/MaisonNeue-Thin.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Thin.svg#MaisonNeue-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Book';
    src: url('../fonts/MaisonNeue-Book.eot');
    src: url('../fonts/MaisonNeue-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Book.woff') format('woff'),
        url('../fonts/MaisonNeue-Book.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Book.svg#MaisonNeue-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Medium.eot');
    src: url('../fonts/MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MaisonNeue-Medium.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Medium.woff') format('woff'),
        url('../fonts/MaisonNeue-Medium.ttf') format('truetype'),
        url('../fonts/MaisonNeue-Medium.svg#MaisonNeue-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}



@font-face{
    font-family: "ApocLC";
    src: url("../fonts/ApocLC-BoldItalic.eot") format('eot');
    font-weight: bold;
    font-style: italic
    
}

$primary: #2B3D25;
$secondary: #F5ECE1;
$white: #FFF;
$red: #ed4337;

$transisition-time: 0.2s;
$transisition-easing: linear;

$max-screen-width: 500px;
