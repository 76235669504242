.space{
    overflow-x: hidden;
    background-color: #{$white};
    padding-bottom: 1px;
    max-width: 100% !important;
    .lead{
        position: relative;
        padding-top: 92px;
        padding-bottom: 92px;
        margin-bottom: 92px;
        min-height: 812px;

        .keyhole-zoom{
            position: absolute;
            top: 0;
            width: 100%;
            height: 812px !important;
            background-image: url(../images/keyhole.png);
            background-size: cover;
            background-position: center bottom;
            .keyhole-cover{
                width: 100%;
                height: 812px !important;
                background-position: center center;
                background-size: 100%;
                transition: all 0.2 linear;
            }
        }
    
        h2{
            margin-top: 0;
            margin-bottom: 56px;
            font-family: Voyage;
            font-style: normal;
            font-weight: bold;
            font-size: 128px;
            line-height: 104px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            letter-spacing: -0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #{$primary};
            z-index: 2;
            position: relative;
            top: -25%;
        }

    }

    h3{
        width: 100%;
        font-family: Maison Neue;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-feature-settings: 'salt' on;
        color: #{$primary};
        margin-bottom: 92px;
    }

    .large-space{
        width: 100%;
        height: auto;
        margin-bottom: 64px;
    }

    .image-left{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 64px !important;
        margin-right: 24px;
        img{
            width: 164px;
            height: auto;
        }
        &.second{
            margin-right: 40px !important;
            img{
                width: 272px;
                height: auto;
            }
        }
    }

    .image-right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 64px !important;
        margin-left: 24px;
        img{
            width: 272px;
            height: auto;
        }
    }

    .image-left:first{
        margin-bottom: 64px;
    }

    .image-right:first-child{
        margin-bottom: 64px;
    }

    img{
        &.full-width{
            width: 100%;
            height: auto;
            margin-bottom: 64px;
        }
    }

}